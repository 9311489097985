export const ResourceState = Object.freeze({
  OPERATING: 'resource_state--operating',
  REMOVED: 'resource_state--removed',
  SETTLEMENT_APPLY: 'resource_state--settlement_apply',
  SETTLEMENT_NOT_APPLY: 'resource_state--settlement_not_apply',
});

export const ResourceType = Object.freeze({
  SOLAR: 'resource_type--solar',
  WIND: 'resource_type--wind',
});

export const State = Object.freeze({
  WATING: 'state--waiting',
  PROCEEDING: 'state--proceeding',
  ENDED: 'state--ended',
});

export const BiddingState = Object.freeze({
  BID: 'bidding_state__bid',
  NON_BID: 'bidding_state__non_bid',
});

export const SearchResourceType = Object.freeze({
  NAME: 'search_resource_type--name',
  GEN_CODE: 'search_resource_type--gen_code',
});

export const BidDataType = Object.freeze({
  ALGORITHM: 'bid_data_type--algorithm',
  SUBMIT: 'bid_data_type--submit',
});

export const BidFeatType = Object.freeze({
  OPERATING_STOP: 'bid_feat_type--operating_stop',
  DISTRIBUTION: 'bid_feat_type--distribution',
});

export const PowerState = Object.freeze({
  NORMAL: 'normal', // 정상
  WARN: 'warn', // 경고
  ERROR: 'error', // 위험
  NONE: 'none', // 누락
});

export const ModifyValueType = Object.freeze({
  VALUE: 'modify_value_type--value',
  RATE: 'modify_value_type--rate',
});

export const ControlState = Object.freeze({
  CONTROL: 'control_state--control',
  NO_CONTROL: 'control_state--no_control',
});

export const AlertType = Object.freeze({
  INSTRUCTION: 'alert_type--instruction',
  LIMITATION: 'alert_type--limitation',
  NONE: 'alert_type--none',
});

export const TaxInvoiceMethod = Object.freeze({
  REGULAR: '정발행',
  REVERSE: '역발행',
  TMP_REGULAR: 'regular',
  TMP_REVERSE: 'reverse',
});

export const SendMethod = Object.freeze({
  EMAIL: 'email',
  SMS: 'sms',
});

export const SettlementPeriod = Object.freeze({
  MONTHLY: 'monthly',
  WEEKLY: 'weekly',
  WEEKLY1: 'weekly1',
  WEEKLY2: 'weekly2',
  WEEKLY3: 'weekly3',
  WEEKLY4: 'weekly4',
});

export const SettlementTemplateType = Object.freeze({
  //api별 사용하는 방식이달라 스네이크 방식 대시방식 생성
  REVERSE_MONTHLY: 'reverse_monthly',
  REVERSE_WEEKLY: 'reverse_weekly',
  REGULAR_MONTHLY: 'regular_monthly',
  REGULAR_WEEKLY: 'regular_weekly',
  REVERSE_MONTHLY_DASH: 'reverse-monthly',
  REVERSE_WEEKLY_DASH: 'reverse-weekly',
  REGULAR_MONTHLY_DASH: 'regular-monthly',
  REGULAR_WEEKLY_DASH: 'regular-weekly',
});

export const BusinessType = Object.freeze({
  NONE: 'none',
});

export const ReferenceArea = Object.freeze({
  DEFAULT: '선택하세요',
  JEJUSI: '제주시',
  SEOGWIPO: '서귀포',
});

export const ManagerType = Object.freeze({
  TAX_INVOICE: 'tax_invoice',
  CEO: 'ceo',
  EQUIPMENT: 'equipment',
  MANAGER: 'manager',
  OTHERS: 'others',
});

export const SendState = Object.freeze({
  IDENTIFY: 'identify',
  UNIDENTIFIED: 'unidentified',
  SEND_IDENTIFY: 'send_identify',
  SEND_UNIDENTIFIED: 'send_unidentified',
});

export const Text = Object.freeze({
  NULLISH_STR: 'text--nullish_str',
  N_A: 'text--n_a',
  NO_INCLUDED_GROUP: 'text--no_included_group',
  YES: 'text--yes',
  NO: 'text--no',

  // server에서 사용하는 단어
  SERVER__LASEE: 'Lasee',
  SERVER__G2POWER: 'G2Power',
  //
  SERVER__ON_OFF: 'On Off',
  SERVER__PERCENTAGE: 'Percentage',
  NO_CONTROL: 'text--no_control',
  //

  SERVER__SOLAR: '태양광',
  SERVER__WIND: '풍력',
  ['SERVER__토지']: '토지',
  ['SERVER__임야(산)']: '임야(산)',
  ['SERVER__건물']: '건물',
  ['SERVER__고속도로유휴부지']: '고속도로유휴부지',
  ['SERVER__수상']: '수상',
  ['SERVER__기타']: '기타',
  ['SERVER__고정형']: '고정형',
  ['SERVER__가변형']: '가변형',
  // 계약
  ['SERVER__현물고객']: '현물고객',
  ['SERVER__장기고정고객']: '장기고정고객',
  ['SERVER__SMP_add_REC']: 'SMP+REC',
  ['SERVER__SMP_add_REC_mul_WEIGHT']: 'SMP+REC*weight',
  ['SERVER__SETTLE_202406']: '1차 (24-06)',
  ['SERVER__SETTLE_202409']: '2차 (24-09)',
  ['SERVER__SETTLE_202503']: '3차 (25-03)',
  ['SERVER__SETTLE_202509']: '4차 (25-09)',
  ['SERVER__SETTLE_202603']: '5차 (26-03)',
  ['SERVER__SETTLE_202609']: '6차 (26-09)',
  //api key in bid
  ['SERVER__DAYAHEAD_WINNING']: 'winning',
  ['SERVER__MINIMUM_BIDDING']: 'minimum_bidding',
  ['SERVER__SECTION_1_BIDDING']: 'section_1_bidding',
  ['SERVER__SECTION_1_PRICE']: 'section_1_price',
  ['SERVER__SECTION_2_BIDDING']: 'section_2_bidding',
  ['SERVER__SECTION_2_PRICE']: 'section_2_price',
  ['SERVER__SECTION_3_BIDDING']: 'section_3_bidding',
  ['SERVER__SECTION_3_PRICE']: 'section_3_price',
  ['SERVER__SECTION_4_BIDDING']: 'section_4_bidding',
  ['SERVER__SECTION_4_PRICE']: 'section_4_price',
  ['SERVER__SECTION_5_BIDDING']: 'section_5_bidding',
  ['SERVER__SECTION_5_PRICE']: 'section_5_price',
  ['SERVER__SECTION_6_BIDDING']: 'section_6_bidding',
  ['SERVER__SECTION_6_PRICE']: 'section_6_price',
  ['SERVER__SECTION_7_BIDDING']: 'section_7_bidding',
  ['SERVER__SECTION_7_PRICE']: 'section_7_price',
  ['SERVER__SECTION_8_BIDDING']: 'section_8_bidding',
  ['SERVER__SECTION_8_PRICE']: 'section_8_price',
  ['SERVER__SECTION_9_BIDDING']: 'section_9_bidding',
  ['SERVER__SECTION_9_PRICE']: 'section_9_price',
  ['SERVER__SECTION_10_BIDDING']: 'section_10_bidding',
  ['SERVER__SECTION_10_PRICE']: 'section_10_price',
  ['SERVER__AVALIABLE_CAPACITY']: 'available_capacity', // getter 함수로 값을 계산한다.
});

// 정산 발송 템플릿 api가 완성되기 전 하드코딩 문구
export const SettlementDefaultTemplate = [
  {
    id: 1,
    manager_name: '김지영',
    manager_email: 'vpp.billing@haezoom.com',
    business_name: '(주)해줌',
    business_address: '서울시 송파구 법원로 128 SKV1 GL 메트로시티 A동 706호',
    mail: {
      subject: `[해줌] {날짜 예약어} 분 전력거래대금 세금계산서 승인 요청의 건ㅁㄴㅇㅁㄴㅇㅁㄴㅇㅁㄴ`,
      content: `<p>안녕하세요.</p>
              <p>(주)해줌 VPP운영팀입니다.</p>
              <p>{날짜 예약어} 전력거래대금 최종정산내역서 전달드리오니, <br>
              첨부된 파일과 수신받으신 역발행 세금계산서 이메일을 확인 후 세금계산서 발행 승인 부탁드립니다. <br>
              발행일 기준 3일 이내 승인 요청드리며, 미승인시 정산금 지급이 지연될 수 있습니다.</p>
              <p>감사합니다.</p>
              <p>해줌 드림</p>`,
    },
    message: {
      subject: '',
      content: `<p>전력거래대금 {날짜 예약어} 세금계산서 승인 요청ㅁㄴㅇㄴㅁㅇ</p>
            <p>안녕하세요 해줌입니다. <br>전력거래대금 최종정산내역서 및 역발행 세금계산서 메일 발송드렸습니다.<br>수신 내역 참고하시어 전력거래대금 역발행 세금계산서 발행 승인 요청드립니다.<br>발행일 기준 3일 이내 승인 요청드리며, 미승인시 정산금 지급이 지연될 수 있습니다. <br>메일이 안와있는 경우 스팸함 확인 부탁드리며, <br>기타 문의사항 발생시 02-889-9941로 연락 부탁드립니다.</p>`,
    },
    file_name: '{year}.{month}_계산서_해줌태양광.pdf, 해줌_사업자등록증.pdf, 세금계산서 작성일자 확인방법.pdf',
    team_name: 'VPP운영팀',
    team_contact: '010-8865-9941',
    cs_contact: '02-889-9941',
    fax_contact: '02-6455-0260',
    email: 'vpp.billing@haezoom.com',
    type: 'reverse_monthly',
    tax_invoice_method: TaxInvoiceMethod.REVERSE,
  },
  {
    id: 2,
    manager_name: '김지영',
    manager_email: 'vpp.billing@haezoom.com',
    business_name: '(주)해줌',
    business_address: '서울시 송파구 법원로 128 SKV1 GL 메트로시티 A동 706호',
    mail: {
      subject: `[해줌] {날짜 예약어} 분 전력거래대금 세금계산서 승인 요청의 건`,
      content: `<p>안녕하세요.</p>
              <p>(주)해줌 VPP운영팀입니다.</p>
              <p>{날짜 예약어} 전력거래대금 최종정산내역서 전달드리오니, <br>
              첨부된 파일과 수신받으신 역발행 세금계산서 이메일을 확인 후 세금계산서 발행 승인 부탁드립니다. <br>
              발행일 기준 3일 이내 승인 요청드리며, 미승인시 정산금 지급이 지연될 수 있습니다.</p>
              <p>감사합니다.</p>
              <p>해줌 드림</p>`,
    },
    message: {
      subject: '',
      content: `<p>전력거래대금 {날짜 예약어} 세금계산서 승인 요청</p>
            <p>안녕하세요 해줌입니다. <br>전력거래대금 최종정산내역서 및 역발행 세금계산서 메일 발송드렸습니다.<br>수신 내역 참고하시어 전력거래대금 역발행 세금계산서 발행 승인 요청드립니다.<br>발행일 기준 3일 이내 승인 요청드리며, 미승인시 정산금 지급이 지연될 수 있습니다. <br>메일이 안와있는 경우 스팸함 확인 부탁드리며, <br>기타 문의사항 발생시 02-889-9941로 연락 부탁드립니다.</p>`,
    },
    file_name: '{year}.{month}_계산서_해줌태양광.pdf, 해줌_사업자등록증.pdf, 세금계산서 작성일자 확인방법.pdf',
    team_name: 'VPP운영팀',
    team_contact: '010-8865-9941',
    cs_contact: '02-889-9941',
    fax_contact: '02-6455-0260',
    email: 'vpp.billing@haezoom.com',
    type: 'reverse_weekly',
    tax_invoice_method: TaxInvoiceMethod.REVERSE,
  },
  {
    id: 3,
    manager_name: '김지영',
    manager_email: 'vpp.billing@haezoom.com',
    business_name: '(주)해줌',
    business_address: '서울시 송파구 법원로 128 SKV1 GL 메트로시티 A동 706호',
    mail: {
      subject: `[해줌] {날짜 예약어} 분 전력거래대금 세금계산서 승인 요청의 건`,
      content: `<p>안녕하세요.</p>
              <p>(주)해줌 VPP운영팀입니다.</p>
              <p>{날짜 예약어} 전력거래대금 최종정산내역서 전달드리오니, <br>
              첨부된 파일과 수신받으신 역발행 세금계산서 이메일을 확인 후 세금계산서 발행 승인 부탁드립니다. <br>
              발행일 기준 3일 이내 승인 요청드리며, 미승인시 정산금 지급이 지연될 수 있습니다.</p>
              <p>감사합니다.</p>
              <p>해줌 드림</p>`,
    },
    message: {
      subject: '',
      content: `<p>전력거래대금 {날짜 예약어} 세금계산서 승인 요청</p>
            <p>안녕하세요 해줌입니다. <br>전력거래대금 최종정산내역서 및 역발행 세금계산서 메일 발송드렸습니다.<br>수신 내역 참고하시어 전력거래대금 역발행 세금계산서 발행 승인 요청드립니다.<br>발행일 기준 3일 이내 승인 요청드리며, 미승인시 정산금 지급이 지연될 수 있습니다. <br>메일이 안와있는 경우 스팸함 확인 부탁드리며, <br>기타 문의사항 발생시 02-889-9941로 연락 부탁드립니다.</p>`,
    },
    file_name: '{year}.{month}_계산서_해줌태양광.pdf, 해줌_사업자등록증.pdf, 세금계산서 작성일자 확인방법.pdf',
    team_name: 'VPP운영팀',
    team_contact: '010-8865-9941',
    cs_contact: '02-889-9941',
    fax_contact: '02-6455-0260',
    email: 'vpp.billing@haezoom.com',
    type: 'regular_monthly',
    tax_invoice_method: TaxInvoiceMethod.REGULAR,
  },
  {
    id: 4,
    manager_name: '김지영',
    manager_email: 'vpp.billing@haezoom.com',
    business_name: '(주)해줌',
    business_address: '서울시 송파구 법원로 128 SKV1 GL 메트로시티 A동 706호',
    mail: {
      subject: `[해줌] {날짜 예약어} 분 전력거래대금 세금계산서 승인 요청의 건`,
      content: `<p>안녕하세요.</p>
              <p>(주)해줌 VPP운영팀입니다.</p>
              <p>{날짜 예약어} 전력거래대금 최종정산내역서 전달드리오니, <br>
              첨부된 파일과 수신받으신 역발행 세금계산서 이메일을 확인 후 세금계산서 발행 승인 부탁드립니다. <br>
              발행일 기준 3일 이내 승인 요청드리며, 미승인시 정산금 지급이 지연될 수 있습니다.</p>
              <p>감사합니다.</p>
              <p>해줌 드림</p>`,
    },
    message: {
      subject: '',
      content: `<p>전력거래대금 {날짜 예약어} 세금계산서 승인 요청</p>
            <p>안녕하세요 해줌입니다. <br>전력거래대금 최종정산내역서 및 역발행 세금계산서 메일 발송드렸습니다.<br>수신 내역 참고하시어 전력거래대금 역발행 세금계산서 발행 승인 요청드립니다.<br>발행일 기준 3일 이내 승인 요청드리며, 미승인시 정산금 지급이 지연될 수 있습니다. <br>메일이 안와있는 경우 스팸함 확인 부탁드리며, <br>기타 문의사항 발생시 02-889-9941로 연락 부탁드립니다.</p>`,
    },
    file_name: '{year}.{month}_계산서_해줌태양광.pdf, 해줌_사업자등록증.pdf, 세금계산서 작성일자 확인방법.pdf',
    team_name: 'VPP운영팀',
    team_contact: '010-8865-9941',
    cs_contact: '02-889-9941',
    fax_contact: '02-6455-0260',
    email: 'vpp.billing@haezoom.com',
    type: 'regular_weekly',
    tax_invoice_method: TaxInvoiceMethod.REGULAR,
  },
];
