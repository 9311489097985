import { Apis, IdLiteral } from "@/constants/api";
import { ControlResponseData, DlResponseData, GroupDispatchOrderHistory, MetaResponseData, MonitoringResponseData, SubstationResponseData } from "@/schema/api/list.schema";
import { useRequestStore } from "@/store/useRequestStore";
import { wrapQueryPromise } from "@/utils/api";
import { SchemaType } from "@/utils/types";
import { useQuery } from "@tanstack/vue-query";
import { computed, reactive, Ref } from "vue";
import { useGroupList } from "./resource.service";

export const useListMetas = () => {
  const { $get } = useRequestStore();

  const substation = useQuery({
    queryKey: [Apis.LIST__SUBSTATION],
    queryFn: () => wrapQueryPromise($get(Apis.LIST__SUBSTATION), SubstationResponseData),
    staleTime: Infinity,
  });

  const dl = useQuery({
    queryKey: [Apis.LIST__DL],
    queryFn: () => wrapQueryPromise($get(Apis.LIST__DL), DlResponseData),
    staleTime: Infinity,
  });

  const monitoring = useQuery({
    queryKey: [Apis.LIST__MONITORING],
    queryFn: () => wrapQueryPromise($get(Apis.LIST__MONITORING), MonitoringResponseData),
    staleTime: Infinity,
  });

  const control = useQuery({
    queryKey: [Apis.LIST__CONTROL],
    queryFn: () => wrapQueryPromise($get(Apis.LIST__CONTROL), ControlResponseData),
    staleTime: Infinity,
  });

  const meta = useQuery({
    queryKey: [Apis.META],
    queryFn: () => wrapQueryPromise($get(Apis.META), MetaResponseData),
    staleTime: Infinity,
  });

  return reactive({
    substation, dl, monitoring, control, meta
  })
}

export const useGroupDispatchOrderHistory = (groupId: Ref<string | null>, bidAt: Ref<string>) => {
  const { data: groups, isFetched: isFetchedGroups } = useGroupList();
  const queryKey = computed(() => groupId.value === '-1'
    ? [Apis.GROUP__$1__DISPATCH_ORDER__HISTORY, groupId.value, bidAt.value, isFetchedGroups.value]
    : [Apis.GROUP__$1__DISPATCH_ORDER__HISTORY, groupId.value, bidAt.value]);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      if (!groupId.value) return Promise.resolve({});

      const get = (groupId: string) => {
        return wrapQueryPromise(
          $get(Apis.GROUP__$1__DISPATCH_ORDER__HISTORY.replace(IdLiteral.GROUP_ID, groupId), {
            params: {
              bid_at: bidAt.value,
            }
          }),
          GroupDispatchOrderHistory,
        )
      }

      if (groupId.value === '-1') {
        if (!isFetchedGroups.value) return Promise.reject(null);

        return Promise.all((groups.value ?? [])?.map(e => ''+e.id).map(get))
          .then((resArr) => {
            const results: SchemaType<typeof GroupDispatchOrderHistory> = {};

            for (const res of resArr) {
              for (const date in res) {
                if (!results[date]) {
                  results[date] = {};
                }
                for (const time in res[date]) {
                  results[date][time] = res[date][time];
                }
              }
            }

            return results;
          });
      } else {
        return get(groupId.value);
      }
    },
  });
}