import {
  AlertType,
  BidDataType,
  BidFeatType,
  ControlState,
  ModifyValueType,
  ResourceState,
  ResourceType,
  ReferenceArea,
  BusinessType,
  ManagerType,
  SettlementPeriod,
  SendState,
  SearchResourceType,
  State,
  TaxInvoiceMethod,
  Text,
  SendMethod,
  SettlementTemplateType,
} from './const';
import { Routes } from './routes';

const TextMap = Object.freeze({
  [Routes.ROOT]: 'Home',
  [Routes.FULLSCREEN]: '',
  [Routes.GNB]: '',
  [Routes.LOGIN]: '로그인',
  [Routes.AUTHORIZED]: '인증완료',
  [Routes.NOTFOUND]: '404',
  [Routes.DASHBOARD]: '대시보드',
  [Routes.MONITORING]: '관제',
  [Routes.MONITORING__GROUP]: '집합자원 관제',
  [Routes.MONITORING__RESOURCE]: '보유자원 관제',
  [Routes.CONTROL]: '제어',
  [Routes.SIMULATION]: '이행능력시험',
  [Routes.BIDDING]: '입찰',
  [Routes.BIDDING__MANAGE_PARTICIPATING_CAPACITY]: '입찰참여 용량관리',
  [Routes.BIDDING__DAY_AHEAD]: '초기 입찰',
  [Routes.BIDDING__REALTIME]: '변경 입찰',
  [Routes.SETTLEMENT]: '정산',
  [Routes.SEND]: '정산 발송',
  [Routes.SETTLEMENT__SETTING]: '설정',
  [Routes.RESOURCE]: '자원관리',
  [Routes.SETTING]: '설정',
  //
  [ResourceState.OPERATING]: '운영',
  [ResourceState.REMOVED]: '해지',
  [ResourceState.SETTLEMENT_APPLY]: '기 입',
  [ResourceState.SETTLEMENT_NOT_APPLY]: '미기입',
  //
  [ResourceType.SOLAR]: '태양광',
  [ResourceType.WIND]: '풍력',
  //
  [State.WATING]: '대기중',
  [State.PROCEEDING]: '진행중',
  [State.ENDED]: '종료',
  //
  [SearchResourceType.NAME]: '보유자원명',
  [SearchResourceType.GEN_CODE]: '발전기코드',
  //
  [BidDataType.SUBMIT]: '수정제출값 보기',
  [BidDataType.ALGORITHM]: '예측 알고리즘값 보기',
  //
  [BidFeatType.OPERATING_STOP]: '기동정지',
  [BidFeatType.DISTRIBUTION]: '출력배분',
  //
  [ModifyValueType.VALUE]: '값 수정',
  [ModifyValueType.RATE]: '비율 수정',
  //
  [Text.NO_INCLUDED_GROUP]: '무소속',
  [Text.NULLISH_STR]: '-',
  [Text.N_A]: 'N/A',
  [Text.YES]: 'Y',
  [Text.NO]: 'N',

  [ControlState.CONTROL]: '제어중',
  [ControlState.NO_CONTROL]: '미제어',

  // server text
  [Text.SERVER__ON_OFF]: 'ON/OFF',
  [Text.SERVER__PERCENTAGE]: '역률제어',
  [Text.NO_CONTROL]: '제어불가',

  [Text.SERVER__LASEE]: '라씨',
  [Text.SERVER__G2POWER]: '지투파워',

  [Text.SERVER__SOLAR]: '태양광',
  [Text.SERVER__WIND]: '풍력',

  [Text['SERVER__토지']]: '토지',
  [Text['SERVER__임야(산)']]: '임야(산)',
  [Text['SERVER__건물']]: '건물',
  [Text['SERVER__고속도로유휴부지']]: '고속도로유휴부지',
  [Text['SERVER__수상']]: '수상',
  [Text['SERVER__기타']]: '기타',
  [Text['SERVER__고정형']]: '고정형',
  [Text['SERVER__가변형']]: '가변형',
  //
  [Text.SERVER__DAYAHEAD_WINNING]: '하루전 낙찰량',
  [Text.SERVER__MINIMUM_BIDDING]: '최소발전용량',
  [Text.SERVER__SECTION_1_BIDDING]: '1구간 발전량',
  [Text.SERVER__SECTION_1_PRICE]: '1구간 가격',
  [Text.SERVER__SECTION_2_BIDDING]: '2구간 발전량',
  [Text.SERVER__SECTION_2_PRICE]: '2구간 가격',
  [Text.SERVER__SECTION_3_BIDDING]: '3구간 발전량',
  [Text.SERVER__SECTION_3_PRICE]: '3구간 가격',
  [Text.SERVER__SECTION_4_BIDDING]: '4구간 발전량',
  [Text.SERVER__SECTION_4_PRICE]: '4구간 가격',
  [Text.SERVER__SECTION_5_BIDDING]: '5구간 발전량',
  [Text.SERVER__SECTION_5_PRICE]: '5구간 가격',
  [Text.SERVER__SECTION_6_BIDDING]: '6구간 발전량',
  [Text.SERVER__SECTION_6_PRICE]: '6구간 가격',
  [Text.SERVER__SECTION_7_BIDDING]: '7구간 발전량',
  [Text.SERVER__SECTION_7_PRICE]: '7구간 가격',
  [Text.SERVER__SECTION_8_BIDDING]: '8구간 발전량',
  [Text.SERVER__SECTION_8_PRICE]: '8구간 가격',
  [Text.SERVER__SECTION_9_BIDDING]: '9구간 발전량',
  [Text.SERVER__SECTION_9_PRICE]: '9구간 가격',
  [Text.SERVER__SECTION_10_BIDDING]: '10구간 발전량',
  [Text.SERVER__SECTION_10_PRICE]: '10구간 가격',
  [Text.SERVER__AVALIABLE_CAPACITY]: '공급가능용량',

  [AlertType.INSTRUCTION]: '급전지시',
  [AlertType.LIMITATION]: '자기제약',
  [AlertType.NONE]: '', // 미노출 상태

  // 정산 발행형식
  [TaxInvoiceMethod.REGULAR]: '정발행',
  [TaxInvoiceMethod.REVERSE]: '역발행',
  // 정산 발행형식
  [TaxInvoiceMethod.TMP_REGULAR]: '정발행',
  [TaxInvoiceMethod.TMP_REVERSE]: '역발행',
  // 정산 발송방식
  [SendMethod.EMAIL]: '이메일',
  [SendMethod.SMS]: '문자',

  // 정산 정산방식
  [SettlementPeriod.MONTHLY]: '월별정산',
  [SettlementPeriod.WEEKLY]: '차수정산',
  [SettlementPeriod.WEEKLY1]: '1주차',
  [SettlementPeriod.WEEKLY2]: '2주차',
  [SettlementPeriod.WEEKLY3]: '3주차',
  [SettlementPeriod.WEEKLY4]: '4주차',

  // 정산 수취확인
  [SendState.UNIDENTIFIED]: '미확인',
  [SendState.IDENTIFY]: '확인',
  [SendState.SEND_UNIDENTIFIED]: '미확인',
  [SendState.SEND_IDENTIFY]: '확인',

  // 정산 템플릿 리스트 명칭
  [SettlementTemplateType.REVERSE_MONTHLY]: '월별정산',
  [SettlementTemplateType.REVERSE_WEEKLY]: '차수정산',
  [SettlementTemplateType.REGULAR_MONTHLY]: '월별정산',
  [SettlementTemplateType.REGULAR_WEEKLY]: '차수정산',
  [SettlementTemplateType.REVERSE_MONTHLY_DASH]: '월별정산',
  [SettlementTemplateType.REVERSE_WEEKLY_DASH]: '차수정산',
  [SettlementTemplateType.REGULAR_MONTHLY_DASH]: '월별정산',
  [SettlementTemplateType.REGULAR_WEEKLY_DASH]: '차수정산',

  [ReferenceArea.JEJUSI]: '제주시',
  [ReferenceArea.SEOGWIPO]: '서귀포',

  [ManagerType.TAX_INVOICE]: '세금계산서',
  [ManagerType.CEO]: '대표자',
  [ManagerType.EQUIPMENT]: '설비',
  [ManagerType.MANAGER]: '실무',
  [ManagerType.OTHERS]: '기타',

  [BusinessType.NONE]: '없음',
});

export const t = (key: string) => TextMap[key.replace(/\/$/, '') as keyof typeof TextMap] ?? key;
